
import Provider from '@/services/provider'
import gtm from '~/mixins/gtm.js'
import vwo from '~/mixins/vwo'
import botChecker from '~/mixins/botChecker.js'
import homeEmbed from '~/components/Templates/Home/HomeEmbed.vue'
import homeNew from '~/components/Templates/Home/HomeNew.vue'
import homeOrganic from '~/components/Templates/Home/HomeOrganic.vue'
import { initVWOAntiflicker, insertVWOScripts } from '~/services/vwo/snippets/antiFlicker-hotmart'

export default {
  mixins: [gtm, vwo, botChecker],

  components: {
    homeEmbed,
    homeNew,
    homeOrganic,
  },

  data() {
    return {
      // GTM
      gtmSubsection: 'Home',
      gtmSubsection2: '',
      // SEO
      title: this.$t('seo.home.title'),
      description: this.$t('seo.home.description'),
      url: `${process.env.BASE_URL}${this.$route.path}`,
      locale: this.$i18n.locales.find(l => l.code === this.$i18n.locale).iso,
      routeName: this.$route.name,
      // DATA
      video: this.$t('pages.home.hero.video'),
      faqSchema: this.$t('pages.home.faq.items'),
      orgSchema: { description: this.$t('seo.home.description'), ...this.$t('seo.organization') },
      isOrganicTestEsMx: false,
    }
  },

  created() {
    if (['es-mx'].includes(this.$i18n.locale)) {
      this.isOrganicTestEsMx = this.$growthbook.isOn('organic-embed-es-mx')
    }
  },

  mounted() {
    // Initiliaze VWO antiflicker
    this.checkBotUserAgent()
    initVWOAntiflicker(document, this.$i18n.locale)
  },

  head() {
    const basePayload = {
      title: this.title,
      description: this.description,
      url: this.url,
      locale: this.locale,
      routeName: this.routeName,
    }

    // Insert VWO Snippet
    let vwoSnippet = insertVWOScripts(this.$i18n.locale, this.isBot)

    return {
      ...Provider.head.useComposeHeadTag({
        ...basePayload,
        schema: [
          { ...Provider.head.setSchemaOrganization({ ...this.orgSchema }) },
          { ...Provider.head.setSchemaWebpage({ ...basePayload }) },
          { ...Provider.head.setSchemaFaq({ items: this.faqSchema }) },
          { ...Provider.head.setSchemaVideo({ video: this.video }) },
        ],
        meta: {},
        link: [
          vwoSnippet.hasOwnProperty('vwoPreconnectLink') ? vwoSnippet.vwoPreconnectLink : {},
          {
            rel: 'preconnect',
            href: 'https://sso.hotmart.com/signup',
          },
        ],
        script: [vwoSnippet.hasOwnProperty('vwoAsyncCode') ? vwoSnippet.vwoAsyncCode : {}],
      }),
      __dangerouslyDisableSanitizersByTagID: vwoSnippet.hasOwnProperty('vwoDisableSanitizers')
        ? vwoSnippet.vwoDisableSanitizers
        : {},
    }
  },

  computed: {
    breadcrumbs() {
      const list = Provider.breadcrumbs.getItemList(payload)

      return list
    },

    isOrganicWithoutTest() {
      return ['pt-br', 'es', 'es-co'].includes(this.$i18n.locale)
    },
  },

  method: {
    async getVwoExperiment() {
      const vwoPageClient = await settingsFile()
      console.log('in my property method', vwoPageClient)
    },
  },
}
